.app {
  font-family: Arial, sans-serif;
}

nav {
  background-color: #282c34;
  padding: 10px;
}

nav ul {
  list-style-type: none;
  display: flex;
  gap: 20px;
  justify-content: center;
  margin: 0;
  padding: 0;
}

nav ul li a {
  color: white;
  text-decoration: none;
  font-size: 18px;
}

nav ul li a:hover {
  text-decoration: underline;
}
