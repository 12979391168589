@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap');

.carousel {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 80vw;
  margin: 0 auto;
  font-family: 'Roboto Mono', monospace;
}

.carousel-content {
  flex-grow: 1;
  overflow: hidden;
}

.token-container {
  display: flex;
  gap: 10px;
}

.token-panel-link {
  color: black;
  text-decoration: none;
  flex: 1;
  min-width: calc(20% - 10px); /* Adjust the percentage to make the cards wider */
}

.token-panel {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  height: 100%;
}

.token-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  background-color: #f0f0f0;
  height: 80px;
}

.token-header h3 {
  margin: 0;
  font-size: 1em;
  text-align: center;
  width: 100%;
}

.token-image-container {
  width: 40px;
  height: 40px;
  margin-right: 5px;
}

.token-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.token-info {
  padding: 10px;
  font-size: 0.8em;
}

.token-info p {
  margin: 5px 0;
}

.carousel-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 24px;
  color: #00FF00 !important; /* Replace with your specific green color code */
  /* Add any other styles you need */
  padding: 10px;
  transition: color 0.3s ease;
}

.carousel-button:hover {
  color: #007bff !important; /* Optional: Change hover color if needed */
}

.carousel-button:focus {
  outline: none;
}

.carousel-arrow {
  color: #00FF00; /* Replace with your specific green color code */
  /* Add any other styles you need */
}

@media (max-width: 1200px) {
  .token-panel-link {
    min-width: calc(25% - 10px); /* Adjust for larger screens */
  }
}

@media (max-width: 768px) {
  .token-panel-link {
    min-width: calc(50% - 10px); /* Adjust for medium screens */
  }
}

@media (max-width: 480px) {
  .token-panel-link {
    min-width: 100%; /* Full width for small screens */
  }
}
