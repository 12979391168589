:root {
  --primary-green: #3CF06B;
  --dark-green: #2AAB4A;
  --light-green: #7FF59E;
  --off-black: #121212;
  --off-white: #F0F0F0;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body, html {
  margin: 0;
  padding: 0;
  width: 100%;
  overflow-x: hidden;
  background-color: var(--off-black);
  color: var(--off-white);
}

/* Temporary debug styles */ 
.landing-page {
  border: 2px solid red;
}

.gradient-banner {
  border: 2px solid blue;
}

.carousels-container {
  border: 2px solid green;
}

.carousel-row {
  border: 2px solid purple;
}

.landing-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 100vh;
  width: 100%;
  max-width: 100vw;
  font-family: 'Helvetica Neue', sans-serif;
  overflow-x: hidden;
}

.gradient-banner {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 1rem 3rem; /* Increased bottom padding to accommodate the X link */
  width: 100%;
  text-align: center;
  color: var(--off-black);
  background: linear-gradient(90deg, var(--primary-green) 0%, var(--dark-green) 100%);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.page-title {
  font-size: 3rem;
  margin-bottom: 0.5rem;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: var(--off-black);
}

.gradient-banner h2 {
  font-size: 1.2rem;
  margin: 0.25rem 0;
  font-weight: 300;
  color: var(--off-black);
}

.carousels-container {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  width: 100%;
  padding: 2rem 1rem;
  box-sizing: border-box;
}

.carousel-row {
  background-color: var(--off-black);
  border-radius: 10px;
  padding: 1.5rem;
  width: 100%;
  box-shadow: 0 2px 4px rgba(60, 240, 107, 0.1);
  border: 1px solid var(--primary-green);
}

.carousel-row h3 {
  margin-bottom: 1rem;
  color: var(--primary-green);
  font-size: 1.5rem;
  font-weight: bold;
}

/* Ensure all text within carousels is white */
.carousel-row p,
.carousel-row span,
.carousel-row div {
  color: var(--off-white);
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .page-title {
    font-size: 2rem;
  }

  .gradient-banner h2 {
    font-size: 1rem;
  }

  .carousels-container {
    padding: 1rem 0.5rem;
  }

  .carousel-row {
    padding: 1rem;
  }

  .carousel-row h3 {
    font-size: 1.2rem;
  }
}

/* Styles for carousel cards */
.carousel-card {
  background-color: var(--off-black);
  color: var(--off-white);
  border: 1px solid var(--primary-green);
  border-radius: 8px;
  padding: 1rem;
  margin: 0.5rem;
  box-shadow: 0 2px 4px rgba(60, 240, 107, 0.1);
}

.carousel-card h4,
.carousel-card h5,
.carousel-card h6 {
  color: var(--primary-green);
  margin-bottom: 0.5rem;
}

.carousel-card p,
.carousel-card span,
.carousel-card div {
  color: var(--off-white);
}

/* If your carousel uses a list structure */
.carousel ul,
.carousel ol,
.carousel li {
  background-color: var(--off-black);
  color: var(--off-white);
}

/* If your carousel uses a grid or flexbox structure */
.carousel-item,
.carousel-slide {
  background-color: var(--off-black);
  color: var(--off-white);
}

/* Ensure any carousel navigation elements (dots, arrows) are visible */
.carousel-nav,
.carousel-dots {
  color: var(--primary-green);
}

.carousel-nav button,
.carousel-dots button {
  background-color: var(--off-black);
  color: var(--primary-green);
  border: 1px solid var(--primary-green);
}

.carousel-nav button:hover,
.carousel-dots button:hover {
  background-color: var(--primary-green);
  color: var(--off-black);
}

/* Target the specific structure of your token cards */
.token-panel {
  background-color: var(--off-black) !important;
  color: var(--off-white) !important;
  border: 1px solid var(--primary-green);
  border-radius: 8px;
  padding: 1rem;
  margin: 0.5rem;
  box-shadow: 0 2px 4px rgba(60, 240, 107, 0.1);
}

.token-header h3 {
  color: var(--primary-green) !important;
}

.token-info p {
  color: var(--off-white) !important;
}

/* If the above doesn't work, try using more specific selectors */
.carousel-content .token-panel,
.token-container .token-panel {
  background-color: var(--off-black) !important;
  color: var(--off-white) !important;
}

.carousel-content .token-header h3,
.token-container .token-header h3 {
  color: var(--primary-green) !important;
}

.carousel-content .token-info p,
.token-container .token-info p {
  color: var(--off-white) !important;
}

/* If you're using inline styles, you might need to use !important */
/* to override them. Use this sparingly and as a last resort. */

/* Ensure the link doesn't affect the card's appearance */
.token-panel-link {
  color: inherit;
  text-decoration: none;
}

.token-panel-link:hover,
.token-panel-link:focus {
  text-decoration: none;
}

/* If there's any default white background being applied, this will catch it */
.carousel-content *,
.token-container * {
  background-color: transparent;
}

.twitter-link {
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  color: var(--off-black);
  text-decoration: none;
  font-weight: bold;
  padding: 0.5rem 1rem;
  border: 2px solid var(--off-black);
  border-radius: 50%; /* Make it circular */
  transition: all 0.3s ease;
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Arial', sans-serif; /* A clean font for the X */
}

.twitter-link:hover {
  background-color: var(--off-black);
  color: var(--primary-green);
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .twitter-link {
    bottom: 0.5rem;
    right: 0.5rem;
    padding: 0.3rem;
    font-size: 0.9rem;
    width: 1.5rem;
    height: 1.5rem;
  }
}

@keyframes sideToSide {
  0% { transform: translateX(0); }
  50% { transform: translateX(10px); }
  100% { transform: translateX(0); }
}

.animate-carousel {
  animation: sideToSide 4s ease-in-out infinite;
}

.animate-carousel.reverse {
  animation-direction: reverse;
}
